<template>
  <b-row>
    <b-col>
      <b-row v-if="canCreate">
        <b-col class="text-right">
          <b-button
            variant="outline-primary"
            class="my-1"
            :to="{name:'add-shipment'}"
          >
            Add shipment
          </b-button>
        </b-col>
      </b-row>
      <b-card
        no-body
        class="shipments-table"
      >
        <b-card-actions
          ref="loadingCard"
          action-refresh
          @refresh="fetchData()"
        >
          <div class="overflow-x-scroll">
            <b-table
              :items="computedTableData"
              responsive
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              stacked="sm"
              class="mb-0"
              show-empty
            >
              <template
                v-if="showLogoColumn"
                #cell(logo)="data"
              >
                <CompanyThumb
                  :logo="data.item.company.logo"
                  :name="data.item.company.name"
                />
              </template>

              <!-- Column: User -->
              <template #cell(users)="data">
                <b-media
                  v-if="data.item.users"
                  vertical-align="center"
                  class="avatar-list"
                >
                  <template #aside>
                    <b-avatar
                      v-for="(audit, index) in data.item.users"
                      :key="index"
                      v-b-tooltip.hover.top="audit.name"
                      size="32"
                      :src="data.item.avatar"
                      :text="getAvatar(audit.name)"
                      :to="{ name: 'user-edit', params: { userId: audit.id } }"
                    />
                  </template>
                </b-media>
              </template>

              <template #cell(document_number)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      <router-link :to="{ name: 'shipment', params: { documentNumber: data.item.document_number, shipmentId: data.item.id }}">
                        {{ data.item.document_number }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(created_at)="data">
                {{ data.item.created_at | formatDate }}
              </template>

              <template #cell(status)="data">
                <div class="d-flex align-items-center">
                  <b-badge
                    v-if="getStatusInfo(data.item.status, 'variant')"
                    pill
                    :variant="getStatusInfo(data.item.status, 'variant')"
                    class="position-relative"
                  >
                    {{ getStatusInfo(data.item.status, 'label') }}
                    <b-badge
                      v-if="data.item.needs_attention_count > 0"
                      pill
                      variant="light"
                      class="text-warning needs-attention-badge"
                    >
                      {{ data.item.needs_attention_count }}
                    </b-badge>
                  </b-badge>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-button-group>
                  <router-link :to="{ name: 'shipment', params: { documentNumber: data.item.document_number, shipmentId: data.item.id }}">
                    <b-button
                      variant="primary"
                      class="router-wrapped-action-button"
                      size="sm"
                    >
                      <feather-icon
                        icon="UploadIcon"
                        class="d-inline"
                      />
                    </b-button>
                  </router-link>
                  <b-button
                    v-if="canEdit"
                    variant="primary"
                    size="sm"
                    @click="edit(data.item.id)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline"
                    />
                  </b-button>
                  <b-button
                    v-if="canDelete"
                    :disabled="data.item.declarations > 0"
                    variant="outline-danger"
                    size="sm"
                    @click="deleteShipment(data.item.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="d-inline"
                    />
                  </b-button>
                </b-button-group>
              </template>

            </b-table>
          </div>
          <pagination
            :event-hub="eventHub"
            :per-page="perPage"
          />
        </b-card-actions>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BTable, BBadge, BButton, BCol, BRow, BAvatar, BMedia, VBTooltip, BButtonGroup,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'
import Pagination from '../../layouts/components/Pagination/Pagination.vue'
import avatarText from '@/views/components/avatar'
import CompanyThumb from '@/views/components/CompanyThumb.vue'
import { getSelectedCompanies } from '@core/utils/filter'

export default {
  components: {
    CompanyThumb,
    BCard,
    BCardActions,
    BTable,
    BBadge,
    BCol,
    BRow,
    BButton,
    Pagination,
    BAvatar,
    BMedia,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['tableData', 'eventHub', 'statuses', 'showLogoColumn'],
  data() {
    return {
      page: 1,
      perPage: 30,
      tableDataStore: [],
      sortBy: null,
      isSortDirDesc: null,
      filters: {},
      fields: [
        ...[this.showLogoColumn ? { key: 'logo', label: 'Company', sortable: false } : null],
        { key: 'document_number', label: 'Document number', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
        { key: 'bags', label: 'Bags', thClass: 'd-none d-sm-table-cell', tdClass: 'd-none d-sm-table-cell', sortable: false },
        { key: 'parcels', label: 'Parcels', thClass: 'text-right d-none d-sm-table-cell', tdClass: 'text-right d-none d-sm-table-cell', sortable: false },
        { key: 'weight', label: 'Weight', thClass: 'text-right d-none d-sm-table-cell', tdClass: 'text-right d-none d-sm-table-cell', sortable: false },
        { key: 'users', label: 'Users', thClass: 'd-none d-sm-table-cell', tdClass: 'd-none d-sm-table-cell', sortable: false },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
          thClass: 'd-none d-sm-table-cell',
          tdClass: 'd-none d-sm-table-cell',
        },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    canDelete() {
      return this.$permissions().hasPermission('delete shipment')
    },
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    canCreate() {
      return this.$permissions().hasPermission('create shipment')
        || this.$permissions().hasPermission('add manifest')
    },
    searchQuery() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments\\`

      // Apply page
      url += `?page=${this.$data.page}`
      url += `&per_page=${this.$data.perPage}`

      // Apply filters
      if (Object.keys(this.$data.filters).length > 0) {
        Object.keys(this.$data.filters).forEach(key => {
          if (this.$data.filters[key] !== null) {
            url += `&${key}=${this.$data.filters[key]}`
          }
        })
      }

      // Apply sorting
      url += `&sortBy=${this.$data.sortBy}`
      url += `&sortDesc=${this.$data.isSortDirDesc}`

      return url
    },
    computedTableData() {
      return this.tableDataStore
    },
  },
  watch: {
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  mounted() {
    this.eventHub.$on('updateData', this.handleUpdateData)
    this.eventHub.$on('updateFilters', this.handleUpdateFilters)
    this.eventHub.$on('pageChange', this.handlePageChange)
    this.tableDataStore = this.$props.tableData
    this.fetchData(true)
  },
  beforeDestroy() {
    this.eventHub.$off('updateData', this.handleUpdateData)
    this.eventHub.$off('updateFilters', this.handleUpdateFilters)
    this.eventHub.$off('pageChange', this.handlePageChange)
  },
  methods: {
    handleUpdateData(data) {
      this.tableDataStore = Object.values(data)
    },
    handleUpdateFilters(data) {
      this.filters = data
      this.fetchData()
    },
    handlePageChange(newPage) {
      this.page = newPage
      this.fetchData()
    },
    getAvatar(name) {
      return avatarText(name)
    },
    edit(id) {
      this.$router.push({ name: 'edit-shipment', params: { shipmentId: id } })
    },
    deleteShipment(id) {
      this.$http.delete(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${id}`)
        .then(response => {
          this.fetchData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    fetchData(isFirstLoad = false) {
      this.$refs.loadingCard.showLoading = true

      if (isFirstLoad) {
        this.filters.companyIds = getSelectedCompanies()
      }

      // Request
      this.$http.get(this.searchQuery)
        .then(response => {
          this.$refs.loadingCard.showLoading = false
          this.$data.shipments = response.data.data
          this.$props.eventHub.$emit('updateData', response.data.data)
          this.$props.eventHub.$emit('updateMeta', response.data.meta)
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    getStatusInfo(statusCode, field) {
      const filteredStatus = this.statuses.find(status => status.value === statusCode)

      return filteredStatus ? filteredStatus[field] : null
    },
  },
}
</script>

<style scoped>
  .router-wrapped-action-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: .5rem;
  }
</style>
